var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{class:{
        'pa-0': true,
        'inner-footer': true,
        'drawer-pad': _vm.brkPoints['brk-md-and-up'] && _vm.layout.drawer && _vm.layout.configuration.footerType !== 'floating',
    },attrs:{"fixed":_vm.isFixed}},[_c('v-content',{staticClass:"footer-container pt-0"},[(_vm.footerInfoLinkLocation === 'top')?_c('v-row',{class:{
                'footer-top': true,
                'small-shadow': _vm.footerInfoLinkLocation === 'top',
            },attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('a',{attrs:{"data-test":"link_footer_privacy","href":"#","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"data-test":"link_footer_isi","href":"#","target":"_blank"}},[_vm._v("Important Safety Information")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"data-test":"link_footer_pi","href":"#","target":"_blank"}},[_vm._v("Prescribing Information")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"data-test":"link_footer_terms","href":"#","target":"_blank"}},[_vm._v("Terms & Conditions")])])],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"footer-bottom",attrs:{"order":_vm.footerInfoLinkLocation === 'top' ? 1 : 0,"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","sm":"11","lg":"8","xl":"6"}},[_c('v-row',{class:{ 'text-center': _vm.brkPoints['brk-sm-and-down'] || _vm.isTopOrBottomBar },attrs:{"justify":"center"}},[_c('v-col',{class:{
                            'text-right': 'left',
                            'text-left': _vm.brkPoints['brk-lg-only' || 'brk-sm-only'] && _vm.footerInfoLinkLocation === 'right',
                        },attrs:{"cols":"12","md":"7","sm":"7","offset":"0","offset-lg":_vm.footerInfoLinkLocation === 'left' ? 1 : 0,"order":_vm.footerInfoLinkLocation === 'left' ? 1 : 0,"lg":_vm.isTopOrBottomBar ? 8 : 6}},[_c('v-row',{staticClass:"img-alignment"},[_vm._m(0)]),_vm._v(" "),_c('v-row',{staticClass:"img-alignment text-left"},[_c('p',[_vm._v("\n                                © 2022 Travere Therapeutics, Inc. All rights reserved. "),_c('br'),_vm._v(" SPA0125\n                            ")])])],1),_vm._v(" "),(_vm.isLeftOrRightBar)?_c('v-col',{class:{
                            'text-right': 'right',
                            'text-left': _vm.brkPoints['brk-lg-only'] && _vm.footerInfoLinkLocation === 'left',
                        },attrs:{"order":_vm.footerInfoLinkLocation === 'left' ? 0 : 1,"align-self":"start","cols":"12","md":"5","lg":"5","sm":"5","offset":"0","offset-lg":_vm.footerInfoLinkLocation === 'left' ? 0 : 1}},[_c('ul',{staticClass:"footer-info-links"},[_c('li',[_c('a',{attrs:{"data-test":"link_footer_privacy","href":"https://travere.com/privacy/","target":"_blank"}},[_vm._v("Privacy Policy")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"data-test":"link_footer_pi","href":"https://travere.com/contact-us","target":"_blank"}},[_vm._v("Contact Us")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"data-test":"link_footer_terms","href":"https://travere.com/terms-and-conditions/","target":"_blank"}},[_vm._v("Terms & Conditions")])])])]):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.footerInfoLinkLocation === 'bottom')?_c('v-row',{staticClass:"footer-top",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"t    ext-center",attrs:{"cols":"12","md":"6"}},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Important Safety Information")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Terms & Conditions")])])],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{staticClass:"footer-logo",attrs:{"src":_vm.$settings.url(_vm.$settings.logoFooter)},on:{"click":function($event){return _vm.logoClick()}}})}]

export { render, staticRenderFns }