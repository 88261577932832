import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { EdgeDocumentsApi } from '@trialcard/apigateway.client/edgeDocumentsApi';
import { PDFDocument, rgb, StandardFonts, PageSizes, PDFImage, PDFPage } from 'pdf-lib';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import EnrollmentModule from '~/store/enrollment';
import PatientModule from '~/store/patient';
import { Settings } from '~/services/settings';
export declare class PageStringObjects {
	type: string;
	label: string;
	value: string;
}
const capitalize = (str: string) =>
    str
        .split(' ')
        .map(str =>
            str
                .charAt(0)
                .toUpperCase()
                .concat(str.slice(1))
        )
        .join(' ');
@InjectModule({ stateFactory: true }, module)
export default class DocumentModule extends InjectVuexModule {
    public static documentFontRegular = StandardFonts.Helvetica;
	public static documentFontBold = StandardFonts.HelveticaBold;
	public static fontSizeRegular = 12;
	public static fontSizeLarge = 20;


    @Resolve
    enrollment!: EnrollmentModule;

    @Resolve
    edgeDocumentsApi!: EdgeDocumentsApi;

    @Resolve
    patient!: PatientModule;

    @Resolve
    settings!: Settings;

    public hasDocument = false;

    @Action({ rawError: true })
    clear() {
        this.updateDocumentStatus(false);
    }

    @Action({ rawError: true })
    setDocumentStatus(newDocumentStatus: boolean) {
        this.hasDocument = newDocumentStatus;
    }

    /**
     * To utilize this function, set "url" to the url where your download button will be.
     * Set the first argument in the "fileUrl" replace method to the entire path (in this case 'enroll/success')
     * Set the output name of the finished pdf as outputFileName.  In this case, it is "copay-card"
     * Prepare your form fields in the PDF utilizing Adobe, and take note of the field names you have chosen
     * Create objects in the "fileData" array for each form field, where the "name" is the name of the field on the pdf
     * The "value" is the data you wish to display in that field.
     * On the portico dashboard for this portal, add the "staticPortalFiles" array to the portal configuration
     * Add the file name as a string within the array.
     */
    @Action({ rawError: true })
    public async fetchDocument() {
        // Below included to allow testing from localhost.
        const url = window.location.href.includes('localhost')
            ? 'https://portal-qa.trialcard.com/cizplam/couponportal/enroll/success'
            : window.location.href;
        const document = await this.edgeDocumentsApi.fillablePdfGenerateFillablePdf({
            fileUrl: url.replace(/enroll\/success\/?/, 'CopayCardPDF.pdf'),
            outputFileName: 'copay-card',
            fileData: [{ name: 'memberNumber', value: this.enrollment.memberNumber || '' }],
        });
        return document;
    }


    // ---------------------------XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-----------------------------------------
    // PDF creatation
    public async createPage(strings: PageStringObjects[]) {
		const pdfDoc = await PDFDocument.create();
		const fontRegular = await pdfDoc.embedFont(DocumentModule.documentFontRegular);
		const fontBold = await pdfDoc.embedFont(DocumentModule.documentFontBold)

		// Add a blank page to the document
		const page = pdfDoc.addPage(PageSizes.Letter);
		const { width, height } = page.getSize();

		// Draw a adds the text to the coverpage
		strings.forEach((item, index) => {
			const isTitle = item.type === 'title';
			const size = isTitle ? DocumentModule.fontSizeLarge : DocumentModule.fontSizeRegular;

			// height measures from bottom-to-top (0 is the bottom, ~800 is the top)
			// (height - 50) -- determines the top margin
			const margin = (height - 50)

			// 1.5 * size -- determines the line height
			// index -- determines how many lines down that particular string is
			const titleLineSpacing = 1.5 * size * index;

			// multiplying by 1.8 -- nearly doubles line spacing to make room for value string
			const lineSpacing = (1.5 * size * index) * 2

			// add size and a little more to move the string into the empty space
			const extraLine = size + 2

			if (isTitle) {
				page.drawText(item.label, {
					x: 50,
					y: margin - titleLineSpacing,
					size: 20,
					font: fontBold,
					color: rgb(0, 0, 0),
				})
			} else {
				page.drawText(item.label, {
					x: 50,
					y: margin - (lineSpacing),
					size,
					font: fontBold,
					color: rgb(0, 0, 0),
				})
				page.drawText(item.value, {
					x: 50,
					y: margin - (lineSpacing + extraLine),
					size,
					font: fontRegular,
					color: rgb(0, 0, 0),
				})

			}

		}
		);

		return pdfDoc;
	}

    public async createPdf() {
		const strings = this.enrollment.prepPdfModel();

		const pdfDoc = await PDFDocument.create()
        // const consolePdf = await pdfDoc.saveAsBase64()
        // console.log(consolePdf);

		for (const stringArray of strings) {
			const page = await this.createPage(stringArray)
			const [copy] = await pdfDoc.copyPages(page, [0])
			pdfDoc.addPage(copy)
		}

		const pdfBytes = await pdfDoc.save();
        const pdfBase = await pdfDoc.saveAsBase64();
        console.log(pdfBase);

		const blob = new Blob([pdfBytes.buffer], { type: 'application/pdf' });

		return blob
	}

    public async uploadPdf() {
		const blob = await this.createPdf()
		const formData = new FormData();
		formData.append('type', '10001');
		formData.append('attachment', blob);

		const axios: AxiosInstance = (this.edgeDocumentsApi as any).axios;
		const axiosRequest: AxiosRequestConfig = {
			url: 'edge/documents/v1/Document/upload',
			method: 'POST',
			data: formData,
			headers: {
				'x-program-id': process.env.programId!,
			},
		};

		await Promise.all([
			(this.edgeDocumentsApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
			(this.edgeDocumentsApi as any).authentications.Bearer.applyToRequest(axiosRequest),
			(this.edgeDocumentsApi as any).authentications.Token.applyToRequest(axiosRequest),
		]);

		const result = await axios.request(axiosRequest);
		return result;
	}

    public async downloadPdf() {
		const blob = await this.createPdf()
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		const fileName = 'pdf';
		link.download = fileName;
		link.click();
	}

	public async combinePages(pages: PDFDocument[]) {
		const pdfDoc = await PDFDocument.create();

		const copiedPages = [] as PDFPage[]

		await pages.forEach(async (page, index) => {
			const [copyPage] = await pdfDoc.copyPages(page, [index])
			copiedPages.push(copyPage)
		})

		copiedPages.forEach(page => {
			pdfDoc.addPage(page)
		})

		const pdfBytes = await pdfDoc.save();

		// Serialize the PDFDocument to bytes (a Uint8Array)
		return pdfBytes;
	}
    // ---------------------------XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-----------------------------------------

    base64ToArrayBuffer(data: string) {
        const input = data.substring(data.indexOf(',') + 1);
        const binaryString = window.atob(input);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName: string, bytes: Uint8Array) {
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = reportName;
        link.download = fileName;
        link.click();
    }

    public async convertImagetoBase64(url: string, callBack: Function) {
        const  xhr = new XMLHttpRequest;
        xhr.responseType = 'blob';

        xhr.addEventListener('load', async function() {
            const  recoveredBlob = await xhr.response;

            const  reader = new FileReader;

            await reader.addEventListener('load', async function() {
                await callBack(reader.result);
            });

            await reader.readAsDataURL(recoveredBlob);
        });

        await xhr.open('GET', url);
        await xhr.send();
    }

    public async convertDataUrltoBlob(dataUrl: string) {
        // separate out the mime component
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]
        const arrayBuffer = await this.base64ToArrayBuffer(dataUrl);

        // generate a new blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {type: mimeString});
        return blob;
    }

    async convertURLToDataUrl(src: string) {
        const result = await fetch(this.settings.url(src))
            .then(response => response.blob())
            .catch(error => console.log(error)) ;

            const objectUrl = URL.createObjectURL(result);
            let dataUrl = '';
            await this.convertImagetoBase64(objectUrl,  async (result: string) => {
                dataUrl = await result
            })
            await new Promise(resolve => setTimeout(resolve, 500));
            return dataUrl;
    }

    @Mutation
    updateDocumentStatus(newDocumentStatus: boolean) {
        this.setDocumentStatus(newDocumentStatus);
    }
}
