import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _010ae228 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _4f93b736 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _4782647d = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _42fdf52e = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _13c3426e = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _15565a6d = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _5db7cb10 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _056a8e6c = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _233087e4 = () => interopDefault(import('..\\pages\\enroll\\clinical-information.vue' /* webpackChunkName: "pages_enroll_clinical-information" */))
const _51d3f58b = () => interopDefault(import('..\\pages\\enroll\\eligibility.vue' /* webpackChunkName: "pages_enroll_eligibility" */))
const _73bc0bd8 = () => interopDefault(import('..\\pages\\enroll\\insurance.vue' /* webpackChunkName: "pages_enroll_insurance" */))
const _46642b37 = () => interopDefault(import('..\\pages\\enroll\\insurance-information.vue' /* webpackChunkName: "pages_enroll_insurance-information" */))
const _896fca3c = () => interopDefault(import('..\\pages\\enroll\\patient-information.vue' /* webpackChunkName: "pages_enroll_patient-information" */))
const _349d80f6 = () => interopDefault(import('..\\pages\\enroll\\prescriber-information.vue' /* webpackChunkName: "pages_enroll_prescriber-information" */))
const _d9808c2a = () => interopDefault(import('..\\pages\\enroll\\prescription-information.vue' /* webpackChunkName: "pages_enroll_prescription-information" */))
const _9bdfd858 = () => interopDefault(import('..\\pages\\enroll\\select-path.vue' /* webpackChunkName: "pages_enroll_select-path" */))
const _0fb48a01 = () => interopDefault(import('..\\pages\\enroll\\success.vue' /* webpackChunkName: "pages_enroll_success" */))
const _4d83e48d = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _4d91fc0e = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _4dae2b10 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _4dbc4291 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _fb2bc064 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _1b1ee5b4 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _88971e94 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _010ae228,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _4f93b736,
    children: [{
      path: "",
      component: _4782647d,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _42fdf52e,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _13c3426e,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _15565a6d,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _5db7cb10,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _056a8e6c,
    name: "upload-documents"
  }, {
    path: "/enroll/clinical-information",
    component: _233087e4,
    name: "enroll-clinical-information"
  }, {
    path: "/enroll/eligibility",
    component: _51d3f58b,
    name: "enroll-eligibility"
  }, {
    path: "/enroll/insurance",
    component: _73bc0bd8,
    name: "enroll-insurance"
  }, {
    path: "/enroll/insurance-information",
    component: _46642b37,
    name: "enroll-insurance-information"
  }, {
    path: "/enroll/patient-information",
    component: _896fca3c,
    name: "enroll-patient-information"
  }, {
    path: "/enroll/prescriber-information",
    component: _349d80f6,
    name: "enroll-prescriber-information"
  }, {
    path: "/enroll/prescription-information",
    component: _d9808c2a,
    name: "enroll-prescription-information"
  }, {
    path: "/enroll/select-path",
    component: _9bdfd858,
    name: "enroll-select-path"
  }, {
    path: "/enroll/success",
    component: _0fb48a01,
    name: "enroll-success"
  }, {
    path: "/errors/400",
    component: _4d83e48d,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _4d91fc0e,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _4dae2b10,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _4dbc4291,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _fb2bc064,
    name: "errors-500"
  }, {
    path: "/patient/patientSearch",
    component: _1b1ee5b4,
    name: "patient-patientSearch"
  }, {
    path: "/",
    component: _88971e94,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
