import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    PayorCoverageEligibilityRequestModel,
} from '@trialcard/apigateway.models';
import { Gender, RoleType } from '@trialcard/enums';

import moment, { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import { Model } from 'vue-property-decorator';
import { forEach } from 'lodash';
import { Settings } from '~/services/settings';

import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates'
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers'
import genders from '~/assets/genders';




@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    isCareGiver: boolean | null = null;
    primaryInsuranceOther = '';
    primaryPharmacyOther = '';
    insuranceCheck: boolean | null = null;

    defaultPatientInformationData = {
        firstName: '' as string,
        mi: '' as string,
        lastName: '' as string,
        dateOfBirth: '' as string,
        addresses: [] as any[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
    };




    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                accountRelationshipIsPrimary: true,
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];
    otherPrescriberData = {
		NameofOffice: '' as string,
		PrescriberSpecialty: '' as string,
		signName: '' as string,
		ext: '' as string,
        OfficePhoneOptional:'' as string,
        quickstartCheckbox: false as boolean,
	};

    defaultSiteData = [
        {
            model: {
                accountRelationshipIsPrimary: true,
                siteRoleType: RoleType.PrescriberSite,
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        addressOne: '',
                        addressTwo: '',
                        zip: '',
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultPayorData = [
        {
            model: {
                payorId: (null as unknown) as number,
                payorType: (null as unknown) as number,
                accountRelationshipIsPrimary: true,
                payorGroup: '',
                payorName: '',
                webAddresses: [],
                addresses: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [],
                contacts: [],
                payorBenefit: {
                    dateOfBirth: null as Moment | null,
                    policyHolderFirstName: '',
                    policyHolderLastName: '',
                    relationshipToPatient: undefined,
                    policyNumber: '',
                    planType: undefined,
                },
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[];

    clinicalSurveyInformation = {
		diagnosis: '' as string,
		icd10: '' as string,
        Proteinuria: '' as string,
		otherDiagnosis: '' as string,
		proteinUriaRadio: '' as string,
		kidneyBiopsyRadio: '' as string,
	};

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    defaultMemberNumber = pharmacyMN.number

    defaultExpirationDate = rolling365

    defaultPreferredInformation = {
        preferredContact: '',
        preferredLanguage: '',
        preferredLanguageOther: ''
    };

    memberNumber = this.defaultMemberNumber;
    expirationDate = this.defaultExpirationDate;
    patientInformationData = { ...this.defaultPatientInformationData };
    careGiverInformationData = { ...this.defaultCareGiverInformationData };
    prescriberData = [ ...this.defaultPrescriberData ];
    siteData = [ ...this.defaultSiteData ];
    payorData = [ ...this.defaultPayorData ];
    contactPreferences = [...this.defaultContactPreferences];
	patientClinicalSurveyInformation = { ...this.clinicalSurveyInformation };

    preferredInformation = this.defaultPreferredInformation;
    patientPrescriberInformation = { ...this.otherPrescriberData };
    payorCoverageAreas = [] as PayorCoverageEligibilityRequestModel[];


    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }

    @Mutation
    public async updatePayors(newPayorData: UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[]) {
        this.payorData = [...newPayorData];
    }

    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newCareGiverInformation };
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public async updatePreferredInformation(newPreferredInformation: { preferredContact: string; preferredLanguage: string; preferredLanguageOther: string; } ) {
        this.preferredInformation = newPreferredInformation
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: Moment) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }
    @Mutation
	public updatePrescriberInformaton(otherPrescriberData: typeof EnrollmentModule.prototype.otherPrescriberData) {
		this.patientPrescriberInformation = otherPrescriberData;
	}

    @Mutation
	public updateClinicalSurveyInformaton(clinicalSurveyInformation: typeof EnrollmentModule.prototype.clinicalSurveyInformation) {
		this.patientClinicalSurveyInformation = clinicalSurveyInformation;
	}

    @Mutation
    public setInsuranceOther(InsuranceOther: string) {
        this.primaryInsuranceOther = InsuranceOther;
    }

    @Mutation
    public setPharmacyOther(PharmacyOther: string) {
        this.primaryPharmacyOther = PharmacyOther;
    }

    @Mutation
    public async updatePayorCoverageAreas(newCoverageAreas : PayorCoverageEligibilityRequestModel[]){
        this.payorCoverageAreas = [...newCoverageAreas];
    }

    @Mutation
    public async updateInsuranceCheckbox(value: boolean){
        this.insuranceCheck = value;

    }

    @Action({ rawError: true })
    public clearStore() {
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
        this.updatePrescribers(this.defaultPrescriberData);
        this.updateSites(this.defaultSiteData);
        this.updatePayors(this.defaultPayorData);
        this.updateContactPreferences(this.defaultContactPreferences);
		this.updateClinicalSurveyInformaton({ ...this.clinicalSurveyInformation });

        this.updatePreferredInformation(this.defaultPreferredInformation);
        this.updatePrescriberInformaton({ ...this.otherPrescriberData });
        this.updateInsuranceCheckbox(false);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        const { prescriberData, siteData, payorData, contactPreferences } = this;
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as UnderlyingSink as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contactPreferences,
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    prescriberData,
                    siteData,
                    payorData,
                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
                payorCoverageAreas: this.payorCoverageAreas
            } as EnrollPatientRequestModel;
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.programId);
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }
// --------------------------------PDF creation-------------------------------------------

prepPdfModel() {
    const patientData = this.patientInformationData;
    const clincialData = this.patientClinicalSurveyInformation;
    const prescriberInformationData = this.prescriberData;

    const strings = [
        [
            { type: 'title', label: 'Patient Information', value: '' },
            { type: 'info', label: 'First Name:', value: patientData.firstName || '' },
            { type: 'info', label: 'MI:', value: patientData.mi || '' },
            { type: 'info', label: 'Last Name:', value: patientData.lastName || '' },
            { type: 'info', label: 'Date of Birth (MM/DD/YYYY):', value: patientData.dateOfBirth || '' },
            { type: 'info', label: 'Gender:', value: patientData.gender === 1 ? 'Male' : 'Female'},
            { type: 'info', label: 'Shipping Address:', value: patientData.addresses.length > 0 ? patientData.addresses[0].addressOne : '' },
            { type: 'info', label: 'Apt #:', value: patientData.addresses.length > 0 ? patientData.addresses[0].aptNum : '' },
            { type: 'info', label: 'Zip Code:', value: patientData.addresses.length > 0 ? patientData.addresses[0].zip : ''},
            { type: 'info', label: 'City:', value: patientData.addresses.length > 0 ? patientData.addresses[0].city : ''},
            { type: 'info', label: 'State:', value: patientData.addresses.length > 0 ? patientData.addresses[0].state : ''},
            { type: 'info', label: 'Mobile Phone:', value: patientData.phoneNumbers.length > 1 ? patientData.phoneNumbers[1].phoneNumber : ''},
            { type: 'info', label: 'Home Phone:', value: patientData.phoneNumbers.length > 0  ? patientData.phoneNumbers[0].phoneNumber : ''},
            { type: 'info', label: 'Email:', value: patientData.emailAddresses.length > 0 ? patientData.emailAddresses[0].address : '' },
        ],

        [
            { type: 'title', label: 'Insurance Information', value: '' },
            { type: 'info', label: 'Patient does not have insurance', value: this.insuranceCheck ? 'Yes' : 'No' }, // checkbox needed
            { type: 'info', label: 'Primary Insurance State:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.addresses[0] ? this.payorData[1].model.addresses[0].state : '' : '' : ''},
            { type: 'info', label: 'Primary Insurance Carrier:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorName : '' : '' },
            { type: 'info', label: 'Name of Insured (Cardholder):', value: this.payorData.length > 0 ? this.payorData[0].model ? this.payorData[0].model.payorBenefit ? this.payorData[0].model.payorBenefit.policyHolderFirstName : '' : '' : ''},
            { type: 'info', label: 'Policy Phone:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorBenefit ? this.payorData[1].model.payorBenefit.phone : '' : '' : ''},
            { type: 'info', label: 'Rx Member ID:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorBenefit ? this.payorData[1].model.payorBenefit.policyNumber : '' : '' : ''},
            { type: 'info', label: 'Rx Group ID:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorBenefit ? this.payorData[1].model.payorBenefit.groupNumber : '' : '' : ''},
            { type: 'info', label: 'Rx BIN:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorBenefit ? this.payorData[1].model.payorBenefit.bin : '' : '' : ''},
            { type: 'info', label: 'Rx PCN:', value: this.payorData.length > 1 ? this.payorData[1].model ? this.payorData[1].model.payorBenefit ? this.payorData[1].model.payorBenefit.pcn : '' : '' : ''},
            { type: 'info', label: 'Primary Insurance State:', value: this.payorData.length > 0 ? this.payorData[0].model ? this.payorData[0].model.addresses[0] ?this.payorData[0].model.addresses[0].state : '' : '' : ''},
            { type: 'info', label: 'Primary Insurance Carrier:',value: this.payorData.length > 0 ? this.payorData[0].model ? this.payorData[0].model.payorName : '': ''},
            { type: 'info', label: 'Policy Phone:', value: this.payorData.length > 0 ? this.payorData[0].model ? this.payorData[0].model.payorBenefit ? this.payorData[0].model.payorBenefit.phone : '' : '' : ''},
        ],
        [
            { type: 'title', label: 'Clinical Information', value: '' },
            { type: 'info', label: 'Diagnosis:', value: clincialData.diagnosis ? 'Primary Immunoglobulin A Nephropathy (IgAN)' : 'other' },
            { type: 'info', label: 'Other:', value: clincialData.otherDiagnosis || ''},
            { type: 'info', label: 'ICD-10 Code:', value: clincialData.icd10 || '' },
            { type: 'info', label: 'Has the patient had a kidney biopsy?:', value: clincialData.kidneyBiopsyRadio },
            { type: 'info', label: 'Patient Proteinuria level:', value: clincialData.proteinUriaRadio ? '<1.0 g/day' : '<1.0 g/day'},
        ],

        [
            { type: 'title', label: 'Prescriber Information', value: '' },
			{ type: 'info', label: 'Prescriber Name:', value: prescriberInformationData.length > 0 ? prescriberInformationData[0].model ? prescriberInformationData[0].model.firstName : '': '' },
			{ type: 'info', label: 'Prescriber NPI #:', value: prescriberInformationData.length > 0 ? prescriberInformationData[0].model ? prescriberInformationData[0].model.npi : '' : '' },
            { type: 'info', label: 'Prescriber Specialty:', value: this.patientPrescriberInformation.PrescriberSpecialty || ''},
			{ type: 'info', label: 'Name of Office:', value: this.patientPrescriberInformation.NameofOffice || ''},
			{ type: 'info', label: 'Office Street Address:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.addresses[0] ? this.siteData[0].model.addresses[0].addressOne : '' : '' : ''},
			{ type: 'info', label: 'Zip:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.addresses[0] ? this.siteData[0].model.addresses[0].zip : '' : '' : ''},
			{ type: 'info', label: 'City:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model .addresses[0] ? this.siteData[0].model .addresses[0].city : '' : '' : ''},
			{ type: 'info', label: 'State:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.addresses[0] ? this.siteData[0].model.addresses[0].state : '' : '' : ''},
			{ type: 'info', label: 'Office Contact Name:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.description : '' : ''},
			{ type: 'info', label: 'Office Contact Email:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.pointOfContacts[0] ? this.siteData[0].model.pointOfContacts[0].emailAddress : '' : '' : ''},
			{ type: 'info', label: 'Office Phone:', value: prescriberInformationData.length > 0 ? prescriberInformationData[0].model ? prescriberInformationData[0].model.phoneNumbers[0] ? prescriberInformationData[0].model.phoneNumbers[0].phoneNumber : '' : '' : ''},
			{ type: 'info', label: 'Office Fax:', value: this.siteData.length > 0 ? this.siteData[0].model ? this.siteData[0].model.pointOfContacts[0] ? this.siteData[0].model.pointOfContacts[0].faxNumber : '' : '' : ''},
            { type: 'info', label: 'Office Contact Phone (if different):', value: this.patientPrescriberInformation.OfficePhoneOptional || ''},
            { type: 'info', label: 'ext:', value: this.patientPrescriberInformation.ext || ''},
			{ type: 'info', label: 'Prescriber signature:', value: this.patientPrescriberInformation.signName || ''},
            { type: 'info', label: "Today's Date:", value: moment().format('MM/DD/YYYY')  },
            { type: 'info', label: 'QuickStart:', value: this.patientPrescriberInformation.quickstartCheckbox ? 'Yes' : 'No' },
        ],
    ]
    strings.forEach( element =>{
    element.forEach(elementTwo =>{
       elementTwo.value =  elementTwo.value ? elementTwo.value.toString() : '';
    })
   })
    return strings;
}
// ---------------------------------------------------------------------------

}
