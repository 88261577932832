











































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
import EnrollmentModule from '~/store/enrollment';

@Component<Footer>({})
export default class Footer extends Vue {
    @Resolve
    public layout!: LayoutModule;
    @Resolve
    public enrollment!: EnrollmentModule;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    public get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }

    public get isTopOrBottomBar() {
        return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
    }

    public get isLeftOrRightBar() {
        return ['left', 'right'].includes(this.footerInfoLinkLocation);
    }

    public get footerLogoSrc() {
        return this.layout.configuration.images.footer || this.$settings.url(this.$settings.footerLogo);
    }

    logoClick() {
        // Clearstore after delay of 200ms to avoid conflict with any pages' beforeDestroy logic.
        setTimeout(() => {
            this.enrollment.clearStore();
            this.$router.push('/');
        }, 70);
    }
}
